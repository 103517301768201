import { NavLink } from 'react-router-dom';


import logoImg from '../assets/logos/nav-logo.png';


const TopNav = () => {
  return (
    <div className='flex justify-between py-4'>

      <div>
        <NavLink
          to='/'
          className='flex self-center'>
          <img
            className='h-9 sm:h-50px'
            src={logoImg}
            alt='Logo' /></NavLink>
      </div>

      <div className='flex'>
        <button className='contact-btn self-center py-2 px-4 sm:py-3.5 sm:px-8'>Contact us</button>
      </div>

    </div>
  )
}

export default TopNav;
