import controllerImg from '../assets/imgs/controler.png';


const Landing = () => {
  return (
    <div className='mb-24'> 

      <div className='mt-16 sm:mt-44 mx-auto xl:mx-0 w-full text-center xl:text-left xl:w-50/100'>
        <h1 className='text-4xl sm:text-78px ft1-bld line-normal'>We are ecruit.gg</h1>
        <p className='text-base sm:text-xl  mt-6'>We are a gaming community focused on connecting esports gamers and players looking for 
           scholarships and position on teams. We are working with teams, coaches, and clubs
           looking for players just like you!</p>
      </div>

      <div className='mt-24 sm:mt-40 w-full xl:w-40/100 mx-auto text-center'>
        <h1 className='text-2xl sm:text-5xl ft1-bld line-normal'>Sign Up to become one of our Beta Testers</h1>
        <p className='text-base sm:text-xl mt-6'>
          Become one of our testers and you will have influence on the
          product and have a chance to win prizes and rewards.</p>
        <button className='btn-pk mt-10'>Contact us at info@ecruit.gg</button>
      </div>

      <div className='mt-24 w-full'>
        <h1 className='text-3xl sm:text-5xl line-normal ft1-bld'>Why Sign Up?</h1>
        <div className='mt-12 flex flex-col xl:flex-row justify-between controller-sec'>
          <div className='w-full xl:w-60/100'>
            <img
              className='xl:w-65/100 mx-auto'
              src={controllerImg}
              alt='Game controller' />
          </div>

          <div className='text-center xl:text-left w-90/100 sm:60/100 xl:w-45/100 mx-auto'>
            <div className='bl-pr pl-6'>
              <h1 className='fs-20 ft1-bld'>Become a Tester</h1>
              <p className='mt-3.5 opacity-70'>As a tester you will have a voice in the products development</p>
            </div>

            <div className='mt-12 bl-pr pl-6'>
              <h1 className='fs-20 ft1-bld'>Be a Leader</h1>
              <p className='mt-3.5 opacity-70'>As one of our testers you will be able to help lead and shape the direction of the
                 platform and gain first access to new features</p>
            </div>

            <div className='mt-12 bl-pr pl-6'>
              <h1 className='fs-20 ft1-bld'>Get Rewarded</h1>
              <ul className='mt-3.5 ml-0 sm:ml-6 xl:list-disc list-outside opacity-70'>
                <li>
                  Be rewarded for playintg games, with the opportunity to win prizes such as:
                <ul className='xl:list-disc list-outside ml-0 sm:ml-4'>
                  <li>GameStop gift cards</li>
                  <li>Cash prizes</li>
                  <li>Gaming chairs, headsets, and more</li>
                  <li>AND the opportunity to come back as a tester for future releases</li>
                </ul>
                </li>
              </ul>
            </div>
          </div>
        </div>

      </div>

      <div className='mt-28 w-full xl:w-55/100 mx-auto text-center'>
        <h1 className='text-2xl sm:text-5xl line-normal ft1-bld'>
          Want to partner? Become one of our partners and get access to your target consumers.</h1>
        <button className='btn-pk mt-10'>Contact us at info@ecruit.gg</button>
      </div>

    </div>
  )
}

export default Landing;
