import { useEffect, useState } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';

import TopNav from './components/TopNav';
import Footer from './components/Footer';
import Landing from './pages/Landing';
import Privacy from './pages/Privacy';
import splash2Img from './assets/imgs/splash_02.png';


function App() {
  const [showSplashes, setShowSplashes] = useState(false);
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === '/') {
      setShowSplashes(true);
    } else {
      setShowSplashes(false);
    }  
  }, [location]);

  return (
    <>
      <div className={`container px-5 md:px-20 relative${showSplashes ? ' splash1' : ''}`}>
      {showSplashes &&
        <>
          <img className='splash2' src={splash2Img} alt='splash' />
          <span className='purple-burst'></span>
          <span className='pink-burst'></span>
        </>}
        <TopNav />
        <Routes>
          <Route path='/' element={<Landing />} />
          <Route path='/privacy-policy' element={<Privacy />} />
        </Routes>
      </div>
      <Footer />
    </>
  );
}

export default App;
