import { Link } from 'react-router-dom';

import logoImg from '../assets/logos/footer_logo.png';


const Footer = () => {
  return (
    <div className='footer'>
      <div className='container px-5 md:px-20 flex justify-between'>

        <div>
          <Link
            to='/'
            className='flex self-center'>
            <img
              className='py-8'
              src={logoImg}
              alt='Logo' /></Link>
        </div>

        <div className='flex'>
          <Link
            className='self-center'
            to='privacy-policy'>Privacy policy</Link>

        </div>

      </div>
    </div>
  )
}

export default Footer;
